.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-dark {
  color: #fff;
}

@each $color, $value in $brands-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $grays {
  @include bg-variant(".bg-gray-#{$color}", $value);
}

.bg-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: ($spacer * 2.5);
  height: ($spacer * 2.5);
}
