// stylelint-disable
// Bootstrap overrides

//
// Color system
//

$gray-base:  #181b1e !default;
$gray-100:   #f0f3f5 !default; // lighten($gray-base, 85%);
$gray-200:   #e4e7ea !default; // lighten($gray-base, 80%);
$gray-300:   #c8ced3 !default; // lighten($gray-base, 70%);
$gray-400:   #acb4bc !default; // lighten($gray-base, 60%);
$gray-500:   #8f9ba6 !default; // lighten($gray-base, 50%);
$gray-600:   #73818f !default; // lighten($gray-base, 40%);
$gray-700:   #5c6873 !default; // lighten($gray-base, 30%);
$gray-800:   #2f353a !default; // lighten($gray-base, 10%);
$gray-900:   #23282c !default; // lighten($gray-base, 5%);

$blue:       #20a8d8 !default;
$red:        #f86c6b !default;
$orange:     #f8cb00 !default;
$yellow:     #ffc107 !default;
$green:      #4dbd74 !default;
$light-blue: #63c2de !default;

$colors: () !default;
$colors: map-merge((
  "light-blue":  $light-blue,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900
), $colors);

$secondary:      $gray-300 !default;
$info:           $light-blue !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:              true !default;
// $enable-rounded:                  false !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:                         #e4e5e6 !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-color:                    $gray-300 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:                  .875rem !default;

// Breadcrumbs

$breadcrumb-bg:                   #fff !default;
$breadcrumb-margin-bottom:        1.5rem !default;
$breadcrumb-border-radius:        0 !default;

// Cards

$card-border-color:               $gray-300 !default;
$card-cap-bg:                     $gray-100 !default;

// Dropdowns

$dropdown-padding-y:              0 !default;
$dropdown-border-color:           $gray-300 !default;
$dropdown-divider-bg:             $gray-200 !default;

// Buttons

$btn-secondary-border:            $gray-300 !default;

// Progress bars

$progress-bg:                     $gray-100 !default;

// Tables

$table-bg-accent:                 $gray-100 !default;
$table-bg-hover:                  $gray-100 !default;

// Forms

$input-group-addon-bg:            $gray-100 !default;
$input-border-color:              $gray-200 !default;
$input-group-addon-border-color:  $gray-200 !default;
