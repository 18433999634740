//
// Base styles
//

.callout {
  position: relative;
  padding: 0 $spacer;
  margin: $spacer 0;
  border-left: 4px solid $border-color;

  @if $enable-rounded {
    border-radius: $border-radius;
  }

  .chart-wrapper {
    position: absolute;
    top: 10px;
    left: 50%;
    float: right;
    width: 50%;
  }
}

.callout-bordered {
  border: 1px solid $border-color;
  border-left-width: 4px;
}
.callout code {
  border-radius: $border-radius;
}
.callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}
.callout p:last-child {
  margin-bottom: 0;
}
.callout + .callout {
  margin-top: - .25rem;
}

//
// Variants
//

@each $color, $value in $theme-colors {
  .callout-#{$color} {
    border-left-color: $value;

    h4 {
      color: $value;
    }
  }
}

// Right-to-Left Support
*[dir="rtl"] {
  .callout {
    border-right: 4px solid $border-color;
    border-left: 0;

    @each $color, $value in $theme-colors {
      &.callout-#{$color} {
        border-right-color: $value;
      }
    }

    .chart-wrapper {
      left: 0;
      float: left;
    }
  }
}
